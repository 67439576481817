
export default {
  data() {
    return {};
  },
  methods: {
    async socialLogin(provider) {
      try {
        const res = await this.$axios.get(
          `/api/socaillogin/${provider}/redirect`
        );
        window.location.href = res.data.url;
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
