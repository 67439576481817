
export default {
  middleware: "guest",

  data() {
    return {
      formData: {
        email: "",
        password: "",
        remember: false,
      },
      formErrors: {},
    };
  },
  methods: {
    async submitForm() {
      try {
        this.formErrors = {}; // Clear previous errors

        const res = await this.$auth.loginWith("local", {
          data: { ...this.formData },
        });
        if (res.data.status) {
          await this.$auth.setUser(res.data.user);
        }
        this.resetForm();
        this.$toast.success("Login successful!", { duration: 4000 });
        this.$router.push(this.localePath("/"));
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Handle validation errors
          this.formErrors = error.response.data.errors;
        }
        console.log(error);
        // Display error toast
        this.$toast.error(
          "An unexpected error occurred. Please try again later.",
          { duration: 4000 }
        );
      }
    },
    resetForm() {
      // Clear form data
      this.formData = {
        email: "",
        password: "",
      };
    },
  },
};
